import {makeAutoObservable} from "mobx";


const logPrefix  = "[DashboardStore]"
export default class DashboardStore {

    constructor(props) {
        this.dashboardRepository = props.dashboardRepository;

        this.isUsageLoading = false;
        this.isUserLoading = false;

        this.usageStatistics = '';

        this.userStatisticsListCount = 0;
        this.userStatisticsList = [];

        this.tempSearchId = ''
        this.searchId = '';

        this.rowsPerPage = 10;
        this.page = 1;

        makeAutoObservable(this);
    }

    init = () => {
        this.usageStatistics = '';
        this.userStatisticsList = [];

        this.tempSearchId = ''
        this.searchId = '';

        this.rowsPerPage = 10;
        this.page = 1;
    }

    setTempSearchId = (id) => {
        this.tempSearchId = id;
    }

    setSearchId = (id) => {
        this.searchId = id;
    }

    setPage = (page) => {
        this.page = page;
    }

    * getUsageStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getUsageStatistics.');
            this.isUsageLoading = true;

            const response = yield this.dashboardRepository.getUsageStatistics();

            this.usageStatistics = response.data;

            console.log(logPrefix, 'Finished getUsageStatistics.');

            callback && callback();
        } catch(e) {
            this.usageStatistics = '';
            console.log(logPrefix, 'Failed getConversationList. error: ' + e);
        } finally {
            this.isUsageLoading = false;
        }
    }

    * getDashboardUserStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getDashboardUserStatistics.');
            this.userStatisticsList = [];

            this.isUserLoading = true;

            const response = yield this.dashboardRepository.getUserStatistics(this.searchId, 5, 1);

            this.userStatisticsListCount = response.data.totalCount;
            this.userStatisticsList = response.data.userList;

            console.log(logPrefix, 'Finished getDashboardUserStatistics.');

            callback && callback();
        } catch(e) {
            this.userStatisticsList = [];
            console.log(logPrefix, 'Failed getDashboardUserStatistics. error: ' + e);
        } finally {
            this.isUserLoading = false;
        }
    }

    * getUserStatistics(callback) {
        try {
            console.log(logPrefix, 'Start getUserStatistics.');
            this.userStatisticsList = [];

            this.isUserLoading = true;

            const response = yield this.dashboardRepository.getUserStatistics(this.searchId, this.rowsPerPage, this.page);

            this.userStatisticsListCount = response.data.totalCount;
            this.userStatisticsList = response.data.userList;

            console.log(logPrefix, 'Finished getUserStatistics.');

            callback && callback();
        } catch(e) {
            this.userStatisticsList = [];
            console.log(logPrefix, 'Failed getUserStatistics. error: ' + e);
        } finally {
            this.isUserLoading = false;
        }
    }
}
