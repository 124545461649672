import React from "react";
import {TableRow, TableHead, TableCell, TableBody, Chip, Tooltip, Typography, Box} from "@mui/material";
import { styled } from "@mui/material/styles";
import {Flex, TableStyle} from '../Common/CommonStyle';
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";

const Root = styled(Box)(() => ({
    width: '100%',
    boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius:8,
    overflow:'hidden',
    '& table':{
    },
    '& > .MuiButton-root':{
        display:'flex',
        margin:'0 auto',
    }
}));

const ChipStyle = styled(Chip)(({ theme }) => ({
    minWidth: 48,
    maxWidth: 100,
    height: 28,
    padding: '4px 10px',
    backgroundColor: '#FFFDE7',
    border:'1px solid #F9CB28',
    borderRadius: 25,
    lineHeight: 'initial',
    boxSizing: 'border-box',
    // '&:nth-of-type(3)':{
    //     maxWidth:100,
    // },
    // '&:not(:nth-of-type(3)) .MuiChip-label':{
    //     overflow:'visible',
    //     whiteSpace:'nowrap',
    //     textOverflow:'initial',
    // },
    display: 'box',
    overflow:'hidden',
    verticalAlign:'top',
    textOverflow: 'ellipsis',
    wordBreak:'break-all',
    '-webkit-box-orient':'vertical',
    '-webkit-line-clamp':1,
    '& .MuiChip-label': {
        padding: 0,
        color: '#666666',
        fontSize: '0.875rem',
        fontWeight: 400
    }
}));
export const Words = styled(Typography)(({ theme}) => ({
    '&.MuiTypography-root': {
        color:'#fff',
        fontSize:'1rem',
        fontWeight:400,
        margin:0,
        padding:0,
        '&:before':{
            content:'","',
            marginRight:4,
        },
        '&:nth-of-type(1):before':{
            content:'""',
            marginRight:0,
        }
    },
}));
export const ButtonStyleText = styled(Typography)(({ theme}) => ({
    '&.MuiTypography-root': {
        fontSize:'1rem',
        fontWeight:'400',
        color: '#333',
    },
}));

function UserSearchTableComponent(props) {
    const {dashboardStore} = props;

    const userList = dashboardStore.userStatisticsList;

    const getDate = (reportCreatedDatetime) => {
        return dayjs(reportCreatedDatetime).format('YYYY년 M월 D일');
    }

    const getTime = (seconds) => {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);
        let remainderSeconds = seconds % 60;

        if (remainderSeconds >= 30) {
            minutes += 1;
        }

        if (minutes >= 60) {
            minutes -= 60;
            hours += 1;
        }

        return hours + "시간 " + minutes + "분";
    }

    const getUserId = (userId) => {
        const searchId = dashboardStore.searchId;

        if (searchId === '') {
            return userId;
        } else {
            const highlightStartIndexList = [];
            const highlightEndIndexList = [];

            let startIndex = 0;
            while (true) {
                const index = userId.indexOf(searchId, startIndex);
                if (index === -1) {
                    break;
                }
                const endIndex = index + searchId.length - 1;

                highlightStartIndexList.push(index);
                highlightEndIndexList.push(endIndex);

                startIndex = index + 1;
            }

            let messageResult = '<div>';

            for (let i = 0; i < userId.length; i++) {
                if (highlightStartIndexList.includes(i) && highlightEndIndexList.includes(i)) {
                    messageResult = messageResult + `<span style="background-color: #E1EFFE">` + userId[i] + '</span>';
                } else if (highlightStartIndexList.includes(i)) {
                    messageResult = messageResult + `<span style="background-color: #E1EFFE">` + userId[i];
                } else if (highlightEndIndexList.includes(i)) {
                    messageResult = messageResult + userId[i] + `</span>`;
                } else {
                    messageResult = messageResult + userId[i];
                }
            }
            messageResult = messageResult + '</div>';

            return <div dangerouslySetInnerHTML={{ __html: messageResult }} />;
        }
    }

    return (
        <Root>
            <TableStyle>
                <TableHead>
                    <TableRow>
                        <TableCell align="center"  sx={{ width: '6%' }}>No.</TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>이메일 계정</TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>이메일 계정</TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>총 대화량 (누적)</TableCell>
                        <TableCell align="left" sx={{ width: '16%' }}>총 이용시간 (누적)</TableCell>
                        <TableCell align="left" sx={{ width: '30%' }}>가장 많이 말한 단어</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {userList.map((user, i) => (
                        <TableRow
                            key={i}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell align="center" component="th" scope="row">
                                {((dashboardStore.page -1) * dashboardStore.rowsPerPage) + i + 1}
                            </TableCell>
                            <TableCell align="left">{getUserId(user.userId)}</TableCell>
                            <TableCell align="left">{getDate(user.lastUsedDatetime)}</TableCell>
                            <TableCell align="left">{user.totalDialogCount}회</TableCell>
                            <TableCell align="left">{getTime(user.totalUsageTime)}</TableCell>
                            <TableCell align="left">
                                <Tooltip
                                    componentsProps={{
                                        tooltip:{
                                            sx: {
                                                maxWidth:'500px!important',
                                                backgroundColor: '#333',
                                                padding:'16px 24px',
                                                borderRadius:1,
                                                boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.20)',
                                    },
                                    },
                                    }}
                                    title={
                                    <Flex>
                                        {user.topicWordList.map((word, i) => (
                                            <Words key={i}>
                                                {word}
                                            </Words>
                                        ))}
                                    </Flex>
                                } placement="bottom-start">
                                    <Flex gap={1} style={{maxWidth:'350px'}}>
                                        {user.topicWordList.map((word, i) => (
                                                <ChipStyle
                                                    label={word}
                                                    key={i}
                                                />
                                        ))}
                                    </Flex>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </TableStyle>
        </Root>
    );
}

export default inject('dashboardStore')(observer(UserSearchTableComponent));
