import {makeAutoObservable} from "mobx";

export const State = {
    Authenticated: 'Authenticated',
    NotAuthenticated: 'NotAuthenticated',
    Pending: 'Pending',
    Failed: 'Failed',
};

export const LocalStorageTokenKey = '_AUTHENTICATION_TOKEN_';

const EmptyLogin = {
    id: '',
    password: '',
};

const EmptyUser = {
    id: '',
    name: '',
    type: '',
    profileImageUrl: '',
    createdDatetime: '',
    updatedDatetime: '',
};

const logPrefix  = "[AuthStore]"
export default class AuthStore {
    constructor(props) {
        this.authRepository = props.authRepository;

        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);

        makeAutoObservable(this);
    }


    invalidateLogin = () => {
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);
    };

    setLoginId = (id) => {
        this.login.id = id;
    }

    setLoginPassword = (password) => {
        this.login.password = password;
    }

    setLoginState = (state) => {
        this.loginState = state;
    }

    isNotAcceptableId = () => {
        if (this.login.id !== '') {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(this.login.id)) {
                return "이메일 형식이 아닙니다.";
            }
        }

        return '';
    }

    * doLogin(history, callback) {
        this.loginState = State.Pending;

        try {
            console.log(logPrefix, 'Start doLogin.');

            this.login.loginType = this.loginType;
            this.login.code = this.loginCode;
            const param = this.login;
            const response = yield this.authRepository.login(param);

            const token = response.data.token;
            const user = response.data.user;

            localStorage.setItem(LocalStorageTokenKey, token);

            console.log('doLogin');
            console.log(this);

            this.loginState = State.Authenticated;
            this.loginToken = token;
            this.loginUser = user;
            this.loginCode = '';

            console.log(logPrefix, 'Finished doLogin.');

            history.push('/dashboard');

            callback && callback();
        } catch (e) {
            console.log(logPrefix, 'Failed doLogin.');
            this.loginState = State.Failed;
            this.loginToken = '';
            this.loginCode = '';
            this.loginUser = Object.assign({}, EmptyUser);
        }
    }

    * checkLogin() {
        const token = localStorage.getItem(LocalStorageTokenKey);

        if(token) {
            try {
                const response = yield this.authRepository.checkLogin();

                const user = response.data;
                this.loginState = State.Authenticated;
                this.loginUser = user;
            } catch(e) {
                this.loginState = State.NotAuthenticated;
                this.loginToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
            }
        }
    }

    * doLogout(callback) {
        try {
            yield this.authRepository.logout();

            console.log(this);
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);

            callback && callback();
        } catch(e) {
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        } finally {
            localStorage.removeItem(LocalStorageTokenKey);
        }
    }
}
