import React from "react";
import {Box, Select} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu, SelectFormControlBox} from "./CommonStyle";
import { ReactComponent as ChevronDownIcon } from "./Images/ChevronDownIcon.svg";

export default function MainSummaryGraphComponent(props) {
    const { width, filter, handleChangeFilter, menuList, disabled } = props;

    return (
                  <SelectFormControlBox variant="outlined" sx={{width:{width}}}>
                      <Select
                          id="filter"
                          value={filter}
                          onChange={handleChangeFilter}
                          disabled={disabled}
                          IconComponent={(props) => (
                              <Box>
                                  <ChevronDownIcon style={{marginTop:4, marginRight:8,}} />
                              </Box>
                          )}
                          MenuProps={{
                              anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left'
                              },
                              transformOrigin: {
                                  vertical: 'top',
                                  horizontal: 'left'
                              },
                              PaperProps: {
                                  sx: {
                                      '&.MuiPaper-root': {
                                          boxShadow: '0 4px 15px 0 rgba(51, 51, 51, 0.15)',
                                          border: '0',
                                          borderRadius: '4px',
                                          boxSizing: 'border-box',
                                          marginTop: '4px',
                                          '& ul': {
                                              padding: '4px 4px'
                                          }
                                      }
                                  }
                              }
                          }}
                      >
                          {menuList.map((list, i) => (
                              <Menu key={i} value={i} disableRipple>
                                  {list.text}
                              </Menu>
                          ))}
                      </Select>
                  </SelectFormControlBox>
    );
}


