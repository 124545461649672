import React from "react";
import {Box, CardHeader, CardContent} from "@mui/material";
import { styled } from "@mui/material/styles";
import {FlexSpace, PageTitle, CardStyle, FlexCenter} from '../Common/CommonStyle';
import CommonSelect from "../Common/CommonSelect";


const Root = styled(Box)(() => ({
    width: '100%',
    display:'grid',
    gridTemplateColumns: '1fr 2fr',
    gap:24,
}));

const CardTitleBox = styled(FlexSpace)(() => ({
    alignItems:'flex-end',
    padding:32,
    borderBottom:'1px solid #F0F1F5',
    '& .MuiCardHeader-root':{
        padding:0,
        border:'0 none',
    },
}));

const EmptyBox = styled(FlexCenter)(() => ({
    width:'100%',
    height:259,
    padding:'16px 0',
    backgroundColor:'#FBFCFE',
    color:'#C3C3C3',
    fontSize:'2.5rem',
    fontWeight:700,
}));

export default function MainDataStatisticsComponent(props) {

    const [filter, setFilter] = React.useState('1');

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };

    const menuList = [
        { text: '맞춤'}, { text: '오늘'}, { text: '어제'}, { text: '이번 주'}, { text: '지난 주'},
        { text: '지난 7일 동안'}, { text: '지난 14일 동안'}, { text: '지난 30일 동안'}, { text: '지난 60일 동안'}, { text: '지난 90일 동안'}, { text: '지난 12개월'},
        { text: '올해 (1월부터 오늘까지)'}, { text: '지난해'},
    ];

    return (
        <Root>
            <CardStyle>
                <CardTitleBox>
                    <CardHeader title={'가장 많이 사용한 단어'} subheader={'가장 많이 사용한 단어를 순위별로 집계한 통계입니다.'}/>
                    <CommonSelect filter={filter} handleChangeFilter={handleChangeFilter} menuList={menuList} disabled={true} width={'93px'}/>
                </CardTitleBox>
                <CardContent>
                    <EmptyBox>
                        준비중
                    </EmptyBox>
                </CardContent>
            </CardStyle>
            <CardStyle>
                <CardTitleBox>
                    <CardHeader title={'교재 / 자유 대화 비교'} subheader={'대화 중에서 교재를 통한 대화와 자유 대화 횟수를 비교할 수 있는 통계입니다.'}/>
                    <CommonSelect filter={filter} handleChangeFilter={handleChangeFilter} menuList={menuList} disabled={true} width={'93px'}/>
                </CardTitleBox>
                <CardContent>
                    <EmptyBox>
                        준비중
                    </EmptyBox>
                </CardContent>
            </CardStyle>
        </Root>
    );
}


