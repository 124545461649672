import React from "react";
import { Box, } from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";
import { ReactComponent as HeartRateIcon } from "../Common/Images/HeartRateIcon.svg";
import { ReactComponent as UserSearchIcon } from "../Common/Images/UserSearchIcon.svg";
import { ReactComponent as ComparisonIcon } from "../Common/Images/ComparisonIcon.svg";
import DashboardMainComponent from "./DashboardMainComponent";
import UserSearch from "./UserSearch";
import {inject, observer} from "mobx-react";


const Root = styled(Box)(() => ({
    width: "100%",
    height: "max-content",
    display:'grid',
    gridTemplateColumns:'300px 1fr',
    backgroundColor:'#FFFDE7',
}));

const Container = styled(Box)(() => ({
    // minWidth: `calc(100vw - ${sideBarWidth}px)`,
    width:'100%', //1460
    height: "initial",
    minHeight:'100vh',
    backgroundColor:'#FFFDE7',
    padding:80,
    overflowX:'auto',
    overflowY:'hidden',
    boxSizing:'border-box',
    '@media all and (max-width: 1980px)': {
        width: '100%',
    },
}));


export const sideBarWidth = 300;
function Dashboard(props) {
    const {dashboardStore} = props;

    const [sidebarValue, setSidebarValue] = React.useState(0);
    const handleChangeMenu = (event, newValue) => {
        dashboardStore.init();
        setSidebarValue(newValue);
    };


    const lnbMenu = [
        {
            icon: <HeartRateIcon/>,
            text: '대시보드'
        },
        {
            icon: <UserSearchIcon/>,
            text: '유저검색'
        },
        {
            icon: <ComparisonIcon/>,
            text: '교재/자유대화 비교'
        },
    ];

    return (
        <Root>
            <Sidebar value={sidebarValue} handleChange={handleChangeMenu} lnbMenu={lnbMenu}/>
            <Container>
                {sidebarValue === 0 &&
                    <DashboardMainComponent handleChangeMenu={handleChangeMenu}/>
                }
                {sidebarValue === 1 &&
                    <UserSearch/>
                }
            </Container>
        </Root>
    );
}

export default inject('dashboardStore')(observer(Dashboard));


