import { Repository } from './Repository';

export default class DashboardRepository extends Repository {
    constructor() {
        super();

        this.dashboardRequestPrefix = '/api/v1/statistics';
    }

    getUsageStatistics = () => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/usage`);
    };

    getUserStatistics = (searchId, rowsPerPage, page) => {
        return this.getRequestPromise('get', this.dashboardRequestPrefix + `/user?searchId=${searchId}&rowsPerPage=${rowsPerPage}&page=${page}`);
    };
}
