import React from "react";
import { Box, } from "@mui/material";
import { styled } from "@mui/material/styles";
import {FlexColumn, PageTitle} from '../Common/CommonStyle';
import MainSummaryComponent from "./MainSummaryComponent";
import MainSummaryGraphComponent from "./MainSummaryGraphComponent";
import MainDataStatisticsComponent from "./MainDataStatisticsComponent";
import MainUserSearchComponent from "./MainUserSearchComponent";


const Root = styled(Box)(() => ({
    width: "100%",
}));


export default function DashboardMainComponent(props) {

    return (
        <Root>
          <PageTitle>대시보드</PageTitle>
            <FlexColumn gap={3}>
                <MainSummaryComponent/>
                <MainSummaryGraphComponent/>
                <MainDataStatisticsComponent/>
                <MainUserSearchComponent handleChangeMenu={props.handleChangeMenu}/>
            </FlexColumn>
        </Root>
    );
}


