import React, {useEffect} from "react";
import { Box, Typography, InputAdornment, IconButton, FormControl,FormHelperText} from "@mui/material";
import { styled } from "@mui/material/styles";
import {ButtonStyle, FlexColumn, FlexColumnCenter, StyledInput} from '../Common/CommonStyle';
import ImgSignInBg from "../Common/Images/ImgSignInBg.svg";
import { ReactComponent as Pokoro } from "../Common/Images/Pokoro.svg";
import { ReactComponent as PokoroText } from "../Common/Images/PokoroText.svg";
import { ReactComponent as EyeOffIcon } from "../Common/Images/EyeOffIcon.svg";
import { ReactComponent as EyeShowIcon } from "../Common/Images/EyeShowIcon.svg";
import {inject, observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {State} from "../../stores/AuthStore";


const Root = styled(Box)(() => ({
    width: "100%",
    height: "100vh",
    display:'grid',
    gridTemplateColumns:'700px 1fr',
    boxSizing: "border-box",
}));

const SplashBox = styled(FlexColumnCenter)(() => ({
    width:'100%',
    height:'100%',
    justifyContent: 'center',
    backgroundImage: `url(${ImgSignInBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition:'0 bottom',
    backgroundColor:'#FFFDE7',
    boxSizing: "border-box",
    '& .MuiTypography-h2':{
        fontSize:'1.375rem',
        fontWeight:700,
        margin:'47px 0 10px',
    },
    '& svg':{
        width:120,
        height:139,
    },
}));

const SplashTitleBox = styled(FlexColumnCenter)(() => ({
    '& svg':{
        width:241,
        height:42,
    },
}));

const Contents = styled(FlexColumnCenter)(() => ({
    width: "100%",
    height:'100%',
    justifyContent: 'center',
    gap:40,
    boxSizing: "border-box",
}));

const ContentTitleBox = styled(FlexColumnCenter)(() => ({
    color:'#212121',
    gap:13,
    '& .MuiTypography-h3':{
        fontSize:'1.625rem',
        fontWeight:'700',
    },
    '& .MuiTypography-subTitle1':{
        fontSize:'1rem',
        fontWeight:400,
    },
}));

const InputBox = styled(FlexColumn)(() => ({
    gap:16,
    '& .MuiFormHelperText-root.Mui-error':{
        color:'#EC2D47',
        marginTop:0,
    },
}));

const ActionBox = styled(FlexColumnCenter)(() => ({
    color:'#C3C3C3',
    gap:10,
    '& .MuiTypography-body2':{
        fontSize:'0.875rem',
        fontWeight:'400',
    },
    '& .MuiTypography-caption':{
        fontSize:'0.75rem',
        fontWeight:400,
    },
}));


const ActionButtonStyle = styled(ButtonStyle)(() => ({
    '& .MuiTypography-root': {
        fontSize:'1rem',
        fontWeight:'400',
        color: '#333',
    },
    '&.Mui-disabled': {
        background: '#F0F1F5!important',
        border:'0 none!important',
        '& .MuiTypography-root': {
            color: '#c3c3c3!important'
        },
    }
}));


function SignIn(props) {
    const history = useHistory();
    const {authStore} = props;
    const {login, loginState} = authStore;
    const [showPassword, setShowPassword] = React.useState(false);

    useEffect(() => {
        const formEl = document.getElementById('form');
        if (formEl) {
            window.addEventListener('submit', (e) => {e.preventDefault();});
        }
    }, []);

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChangeId = (event) => {
        authStore.setLoginId(event.target.value);
    }

    const handleChangePassword = (event) => {
        authStore.setLoginPassword(event.target.value);
    }

    const handleKeyUpPassword = (event) => {
        if(event.keyCode === 13 && login.id !== '' && login.password !== '' && authStore.isNotAcceptableId() === '') {
            handleLogin();
        }
    };

    const handleLogin = () => {
        authStore.doLogin(history);
    }

    return (
        <Root>
            <SplashBox>
                <Pokoro/>
                <SplashTitleBox>
                    <Typography variant={'h2'}>둘도 없는 내 친구</Typography>
                    <PokoroText/>
                </SplashTitleBox>
            </SplashBox>
            <Contents>
                <ContentTitleBox>
                    <Typography variant={'h3'}>안녕하세요. 만나서 반가워요!</Typography>
                    <Typography variant={'subTitle1'}>포코로의 사용량을 확인할 수 있는 관리자 페이지입니다.</Typography>
                </ContentTitleBox>
                <InputBox>
                    <FormControl variant="standard">
                        <StyledInput
                            variant="outlined"
                            fullWidth
                            placeholder={'이메일을 입력해주세요'}
                            width={'387px'}
                            onChange={handleChangeId}
                            error={(authStore.isNotAcceptableId() !== '' || loginState === State.Failed)}
                        />
                            {authStore.isNotAcceptableId() !== '' &&
                                <FormHelperText id="component-error-text" style={{color: '#FF0000'}}>{authStore.isNotAcceptableId()}</FormHelperText>
                            }
                    </FormControl>
                    <form id='form'>
                        <FormControl variant="standard">
                            <StyledInput
                                variant="outlined"
                                fullWidth
                                placeholder={'비밀번호를 입력해주세요'}
                                width={'387px'}
                                type={showPassword ? 'text' : 'password'}
                                onChange={handleChangePassword}
                                onKeyUp={handleKeyUpPassword}
                                error={(authStore.isNotAcceptableId() !== '' || loginState === State.Failed)}
                                autoComplete="off"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                                disableRipple
                                            >
                                                {showPassword ? <EyeShowIcon /> : <EyeOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {loginState === State.Failed &&
                                <FormHelperText id="component-error-text" style={{color: '#FF0000'}}>이메일 또는 비밀번호가 다릅니다.</FormHelperText>
                            }
                        </FormControl>
                    </form>
                </InputBox>
                <ActionBox>
                    <ActionButtonStyle width={'387px'} height={'54px'} background={'#FDD751'} borderradius={'4px'} disabled={login.id === '' || login.password === '' || authStore.isNotAcceptableId() !== ''} onClick={handleLogin}>
                        <Typography>로그인</Typography>
                    </ActionButtonStyle>
                    <Typography variant={'body2'}>부여받은 계정을 잃어버렸을 경우, 네오랩컨버전스에 문의해주세요</Typography>
                    <Typography variant={'caption'}>support@neolab.net</Typography>
                </ActionBox>
            </Contents>


        </Root>
    );
}

export default inject('authStore')(observer(SignIn));