import { default as AuthStore } from "./AuthStore";
import DashboardStore from "./DashboardStore";

import AuthRepository from "../repositories/AuthRepository";
import DashboardRepository from "../repositories/DashboardRepository";

const authRepository = new AuthRepository();
const dashboardRepository = new DashboardRepository();

export const stores = {
    authStore:  new AuthStore({authRepository}),
    dashboardStore :  new DashboardStore({dashboardRepository}),
};