import { styled } from '@mui/material/styles';
import {
    Box,
    Button,
    TextField,
    FormControl,
    Typography,
    Card,
    MenuItem,
    Table,
    Pagination
} from '@mui/material';

export const StyledInput = styled(TextField)(({ theme, width, height, fontSize}) => ({
    '&.MuiFormControl-root': {
        width: width ? width : 'calc(100% - 2px)',
        boxSizing: 'border-box'
    },
    '& .MuiInputBase-root': {
        width: '100%',
        minHeight: 40,
        height: height ? height : 52,
        background: '#fff',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
        padding: '8px 15px !important',
        '& input': {
            padding: '0 !important',
            fontSize: fontSize ? fontSize : '1rem',
            color: '#333',
            fontWeight: 400,
            opacity: 1,
            lineHeight: '22.4px',
            '&::placeholder': {
                color: '#C3C3C3',
                opacity: 1,
            }
        },
        '& .MuiOutlinedInput-notchedOutline': {
            width: '100%',
            border: '1px solid #C9C9CA',
            boxSizing: 'border-box'
        },
        '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #C9C9CA'
            }
        },
        '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #F9CB28'
            }
        },
        '&.Mui-error': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #F64860',
            },
        },

    }
}));

export const SelectFormControlBox = styled(FormControl)(({ theme }) => ({
    '&.MuiFormControl-root': {
        // minWidth: 'calc(100% - 2px)',
        marginRight: 0,
        justifyContent:'center',
        '& .MuiInputBase-root': {
           // width:width ? width : 161,
            minHeight: 33,
        },
        '& .MuiSelect-select': {
            width: '100%',
            boxSizing: 'border-box',
            padding: '8px!important',
            fontSize: '0.875rem',
            color: '#333',
            fontWeight: 400,
            lineHeight: '19.6px',
            background: '#fff'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            left: 0
        },
        '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
            {
                border: '1px solid #C9C9CA',
                borderRadius: 4
            },
        '& svg': {
            marginTop: -4,
            right: 10,
            '&.Mui-disabled': {
                '& path': {
                    fill: '#BBBBBB'
                }
            }
        },
        '&.Mui-disabled': {
            '& .MuiSelect-select': {
                color: '#BBBBBB'
            },
            '& input': {
                color: '#BBBBBB'
            },
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #BBBBBB'
            }
        }
    }
}));

export const Menu = styled(MenuItem)(({ theme }) => ({
    '&.MuiMenuItem-root': {
        width: '100%',
        fontSize: '0.875rem',
        color: '#111',
        boxSizing: 'border-box',
        height: 47,
        borderRadius: 4,
        padding: '0 12px',
        '&:hover': {
            background: '#f5f6fa'
        },
        '&.Mui-selected': {
            background: '#F5F6FA',
            fontWeight: 400
        },
        '&.Mui-disabled': {
            color: '#d9d9d9'
        }
    }
}));

export const Flex = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

export const FlexColumn = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
}));

export const FlexColumnCenter = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

export const FlexSpace = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}));

export const FlexCenter = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));

export const FlexEnd = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
}));

export const ButtonStyle = styled(Button)(({ theme, width, height, background, border, borderRadius, hoverBk, hoverColor }) => ({
    '&.MuiButtonBase-root': {
        minWidth: width ? width : '100%',
        height: height,
        padding: '0 8px',
        background: background,
        border: border ? `1px solid ${border}` : background,
        borderRadius: borderRadius ? borderRadius : 4,
        boxSizing: 'border-box',
        '&:hover': {
            background: hoverBk ? hoverBk : background,
            border: hoverBk ? `1px solid ${hoverBk}` : border ? `1px solid ${border}` : background,
            '& p': {
                color: hoverColor
            }
        },
        '& .MuiButton-startIcon': {
            marginLeft: 0
        },
        '&.Mui-disabled': {
            background: '#DAE1E9',
            border: '1px solid #DAE1E9',
            '& p': {
                color: '#979DA4'
            },
            '& .arrow-clockwise': {
                fill: '#979DA4'
            }
        }
    }
}));


export const PageTitle = styled(Typography)(({ theme,  color, fontSize, fontWeight, mb  }) => ({
    '&.MuiTypography-root': {
        color: color ? color : '#FDD751',
        fontSize: fontSize ? fontSize :'2.5rem',
        fontWeight: fontWeight ? fontWeight :700,
        textTransform: 'none',
        marginBottom: mb ? mb :60,
    }
}));

export const TextStyle = styled(Typography)(({ theme, color, fontSize, fontWeight }) => ({
    '&.MuiTypography-root': {
        color: color ? color : '#fff',
        fontSize: fontSize ? fontSize : '1.125rem',
        fontWeight: fontWeight ? fontWeight : 600,
        textTransform: 'none',
    }
}));

export const CardStyle = styled(Card)(({ theme}) => ({
    background:'#fff',
    boxShadow:'2px 2px 8px rgba(0, 0, 0, 0.15)',
    borderRadius:16,
    '& .MuiCardHeader-root': {
        padding:'16px 24px',
        borderBottom:'1px solid #F0F1F5',
        '& .MuiCardHeader-title': {
            color: '#333',
            fontSize: '1.25rem',
            fontWeight: 700,
            lineHeight: '28px',
        },
        '& .MuiCardHeader-subheader':{
            color:'#71717a',
            fontSize:'0.875rem',
            lineHeight:'19.6px',
        },
    },
    '& .MuiCardContent-root': {
        padding:'16px 24px',
    },
}));

export const TableStyle = styled(Table)(({ theme,  }) => ({
    '& .MuiTableRow-head':{
        '& .MuiTableCell-head':{
            borderBottom:'1px solid #F0F1F5',
            borderTop:'1px solid #F0F1F5',
            background:'#FBFCFE',
            fontWeight:400,
        }
    },
    '& .MuiTableCell-body, & .MuiTableCell-body:last-child th':{
        borderBottom:'1px solid #F0F1F5!important',
        background:'#fff',
    },
}));

export const PaginationStyle = styled(Pagination)(() => ({
    width:'100%',
    display:'flex',
    justifyContent : 'center',
    '& .MuiPagination-root':{
        margin:'0 auto',
    },
    '& .MuiPaginationItem-root':{
        fontSize:'0.75rem',
        color:'#333',
        '&.Mui-selected, &:hover':{
            background:'#FDD751',
            color:'#fff',
        },
    },
}));