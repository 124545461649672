import React from "react";
import {Box, Tab, Tabs} from "@mui/material";
import { styled } from "@mui/material/styles";
import {CardStyle, FlexCenter, FlexColumnCenter, FlexSpace} from "../Common/CommonStyle";
import CommonSelect from "../Common/CommonSelect";
import {ReactComponent as ExclamationCircleIcon} from "../Common/Images/ExclamationCircleIcon.svg";

const Root = styled(CardStyle)(() => ({
    width: '100%',
}));


const Contents = styled(FlexColumnCenter)(() => ({
    padding:32,

}));

const EmptyBox = styled(FlexCenter)(() => ({
    width:'100%',
    height:259,
    padding:'16px 0',
    backgroundColor:'#FBFCFE',
    color:'#C3C3C3',
    fontSize:'2.5rem',
    fontWeight:700,
}));

const TabTitleBox = styled(FlexSpace)(() => ({
    alignItems:'flex-end',
    padding:32,
    borderBottom:'1px solid #F0F1F5'
}));

const TabsStyle = styled(Tabs)(({ theme }) => ({
    display:'flex',
    alignItems:'center',
    minHeight:'auto',
    '& .MuiTabs-indicator':{
        display:'none',
    },
    '& .MuiTabs-flexContainer':{
        gap:32,
    },
    '& .MuiTabScrollButton-root.Mui-disabled':{
        width:0,
    },
}));
const TabStyle = styled(Tab)(({ theme }) => ({
    '&.MuiTab-root':{
        minHeight:'auto',
        display:'flex',
        alignItems:'flex-start',
        justifyContent:'flex-start',
        border:'0 none',
        padding:0,
        color:'#C3C3C3',
        fontSize:'1.25rem',
        fontWeight:700,
        textAlign:'left',
        '&.Mui-selected, &:hover':{
            backgroundColor:'#fff',
            color:'#333',
            '& svg path':{
                stroke:'#333',
            },
        },
        '& svg':{
            width:24,
            height:24,
            marginLeft:'4px!important',
            marginRight:'0px!important',
        },
    },
}));

export default function MainSummaryGraphComponent(props) {

    const [tabValue, setTabValue] = React.useState(0);
    const [filter, setFilter] = React.useState('7');

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeFilter = (event) => {
        setFilter(event.target.value);
    };

    const tabList = [
        {text: '전체사용자', icon:''},
        {text: '신규 가입자', icon:<ExclamationCircleIcon/> },
        {text: '활성 사용자', icon:<ExclamationCircleIcon/>},
        {text: '포코로 대화량', icon:<ExclamationCircleIcon/>},
        {text: '포코로 이용시간', icon:<ExclamationCircleIcon/>},
    ];

   const menuList = [
       { text: '맞춤'}, { text: '오늘'}, { text: '어제'}, { text: '이번 주'}, { text: '지난 주'},
       { text: '지난 7일 동안'}, { text: '지난 14일 동안'}, { text: '지난 30일 동안'}, { text: '지난 60일 동안'}, { text: '지난 90일 동안'}, { text: '지난 12개월'},
       { text: '올해 (1월부터 오늘까지)'}, { text: '지난해'},
   ];

    return (
          <Root>
              <TabTitleBox>
                  <TabsStyle
                      value={tabValue}
                      onChange={handleChangeTab}
                  >
                      {tabList.map((item, i) => (
                          <TabStyle
                              key={i}
                              label={item.text}
                              icon={item.icon}
                              iconPosition="end"
                              disableRipple
                          />
                      ))}
                  </TabsStyle>
                  <CommonSelect filter={filter} handleChangeFilter={handleChangeFilter} menuList={menuList} disabled={true} width={'161px'} />
              </TabTitleBox>
                <Contents>
                    <EmptyBox>
                        준비중
                    </EmptyBox>
              {/*{tabValue == '0' &&*/}
              {/*    <EmptyBox>*/}
              {/*        준비중*/}
              {/*    </EmptyBox>*/}
              {/*}*/}
                </Contents>
          </Root>
    );
}


